.header{
    width: 100%;
    height: 60px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding: 12px 34px;
    top: 0;
    left: 0;
    z-index: 5;
}
.profile{
    display: flex;
    align-items: center;
    gap: 10px;
font-size: 14px;
font-weight: 500;
}
.admin{
    border-radius: 36.221px;
background: lightgray 50% / cover no-repeat;
width: 35px;
height: 35px;
}
.logout{
    font-size: 13px;
letter-spacing: -0.26px;
margin: 10px 20px;
cursor: pointer;
}