.section {
    padding: 26px;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    gap: 20px;
}

.card_wrapper {
    background: #fff;
    flex-grow: 1;
    padding: 28px;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    border-radius: 10px;
}

.card_wrapper img {
    width: 55px !important;
    border-radius: 10px;
}

.card_content {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
}

.card_content h3 {
    font-size: 22px;
}

.card_content p {
    font-size: 16px;
}