.selectinput svg {
    width: 30px;
    height: 17px;
    margin-right: 13px;
}

.search {
    border-radius: 7px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    width: 222px;
    height: 38px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    padding-left: 11px;
}

.search input {
    color: #878790;
    font-family: Work Sans;
    font-size: 14px;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.2px;
    border: none;
    outline: none;
    padding: 0 5px;
}

.table {
    width: 100%;
    padding: 33px 4px;
    min-height: 60vh;
}

.head {
    width: 100%;
    color: #878790;
    font-family: Work Sans;
    font-size: 13px;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    padding: 5px 25px 23px 25px;
}

.list {
    width: 100%;
    color: var(--Gray, #3A3A49);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    padding: 18px 25px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #E9EFF4;
}

.list p span {
    color: var(--green-800, #0F5B1D);
    font-family: Work Sans;
    font-size: 12px;
    padding: 5px 10px 7px 10px;
    border-radius: 20px;
    background: var(--green-100, #E6FBD9);
}
.error{
    color: red;
    font-size: 13px;
    padding-top: 8px;
    margin-top: -1px;
    z-index: 3;
    position: relative;
    border-top: 1px rgba(232, 14, 14, 0.7) solid;
}
.list p .inactive {
    background-color: var(--red-100, #FFE8D7);
    color: #FF0000;
}

.list p svg {
    cursor: pointer;
}

.list p svg:hover {
    opacity: 0.7;
}

.page {
    display: flex;
    align-items: center;
    width: 150px;
    color: var(--Gray, #3A3A49);
    font-family: Work Sans;
    gap: 20px;
    font-size: 13px;
    letter-spacing: -0.26px;
    margin: 10px 50% 10px 35%;
}

.page div {
    color: white;
    background-color: #242220;
    padding: 5px 11px;
    align-items: center;
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    border-radius: 150px;
}

.form {
    margin: 40px 15px;
    width: 100%;
}

.form label {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}

.form input {
    color: #8D8D8D;
    font-family: Poppins;
    font-size: 14px;
    border: none;
    width: 100%;
    outline: none;
    padding: 10px 0;
    border-bottom: 1px solid #DFDFDF;
}

.formbtn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 60px;
}