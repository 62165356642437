.homepage{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    background: linear-gradient(180deg, #242220 0%, #000 100%);
}
.hero{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}
.login{
    background-color: white;
    width: 650px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #333;
}
.qr{
    width: 468px;
    overflow: hidden;
    height: 157px;
}
.logo{
    width: 100%;
    height: auto;
}
.circle{
    width: 400px;
height: 400px;
border-radius: 500px;
position: absolute;
bottom: -190px;left: -120px;
border: 1px solid white;
}
.circle2{
    width: 400px;
height: 400px;
border-radius: 500px;
position: absolute;
bottom: -170px;left: -180px;
border: 1px solid white;
}
.text{
    font-family: 'Poppins', sans-serif;
font-size: 26px;
font-weight: 700;
}
.log{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
}
.input{
    width: 100%;
    display: flex;
padding: 18px 26px;
gap: 10px;
border-radius: 30px;
border: 1px solid #EEE;
margin: 16px 0;
}
.input input{
    border: none;
    opacity: 0.5;
    outline: none;
    color: #333;
font-family: Poppins;
font-size: 14px;
}
.forget{
    color: #333;
font-family: Poppins;
font-size: 14px;
width: 100%;
opacity: 0.7;
margin: 8px;
border: none;
}
.loggedin{
    width: 100%;
    border-radius: 30px;
background: #000;
transition: all 0.3s;
    color: white;
    display: flex;
padding: 12px 26px;
flex-direction: column;
justify-content: center;
align-items: center;
}
.loggedin:focus{
    opacity: 0.7;
}