.search {
    border-radius: 7px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    width: 222px;
    height: 38px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    padding-left: 11px;
}

.search input {
    color: #878790;
    font-family: Work Sans;
    font-size: 14px;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.2px;
    border: none;
    outline: none;
    padding: 0 5px;
}

.selectinput svg {
    width: 30px;
    height: 17px;
    margin-right: 13px;
}

.survey {
    margin-top: 27px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.table {
    width: 100%;
    padding: 39px 4px 33px 4px;
    min-height: 60vh;
}

.head {
    width: 100%;
    color: #878790;
    font-family: Work Sans;
    font-size: 13px;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    padding: 5px 25px 23px 25px;
}

.list {
    width: 100%;
    color: var(--Gray, #3A3A49);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    gap: 5px;
    line-height: normal;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    padding: 18px 25px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #E9EFF4;
}

.active {
    background-color: #E9E9E9;
}

.list p span {
    color: var(--green-800, #0F5B1D);
    font-family: Work Sans;
    font-size: 12px;
    padding: 5px 10px 7px 10px;
    border-radius: 12px;
    background: var(--green-100, #E6FBD9);
}

.list p .inactive {
    background-color: var(--red-100, #FFE8D7);
    color: #FF0000;
}

.list p svg {
    cursor: pointer;
}

.list p svg:hover {
    opacity: 0.7;
}

.page {
    display: flex;
    align-items: center;
    width: 150px;
    color: var(--Gray, #3A3A49);
    font-family: Work Sans;
    gap: 20px;
    font-size: 13px;
    letter-spacing: -0.26px;
    margin: 35px auto;
}

.page div {
    color: white;
    background-color: #242220;
    padding: 5px 11px;
    align-items: center;
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    border-radius: 150px;
}

.profile_view {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #E9EFF4;
    border-top: none;
    background: #FFF;
    margin-top: -10px;
    padding: 17px 10px 14px 26px;
    display: flex;
    gap: 20px;
}

.profile_box {
    width: 78%;
    border-radius: 12px;
    border: 1px solid var(--gray-200, #EAECEE);
    background: var(--base-background-white, #FFF);
    color: #4F5B67;
    font-family: Work Sans;
    font-size: 12px;
    line-height: 150%;
    /* 18px */
    padding: 8px;
}

.box_content {
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: center;
    align-items: center;
}

.error {
    color: red;
    font-size: 13px;
    padding-top: 8px;
    margin-top: -1px;
    z-index: 3;
    position: relative;
    border-top: 1px rgba(232, 14, 14, 0.7) solid;
}

.dp {
    margin-top: 32px;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
}

.dpname {
    color: var(--gray-800, #242D35);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 500;
    margin: 21px auto 4px auto;
}

.box_contentwo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: 10;
}

.profile_box span {
    display: flex;
}

.profile_box span p {
    width: 150px;
    padding-top: 6px;
    padding-left: 31px;
}

.profile_box span .equal {
    width: 30px;
    padding-left: 25px;
}

.profile_box span .details {
    color: var(--gray-800, #242D35);
    font-size: 12px;
    width: 100%;
}

.locate {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.map {
    width: 20%;
    height: 122px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.map img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}