.navbar {
    width: 250px;
    height: 100vh;
    background: black;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main {
    width: 800px;
    height: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 250px);
    padding-top: 60px;
    overflow-x: hidden;
    /* min-height: 100vh; */
    /* background: #f7f7f7; */
}

.menu {
    margin: 5px 20px;
    padding: 16px 20px;
    text-decoration: none;
    display: flex;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    color: rgba(255, 255, 255, 0.56);
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    position: relative;
}

.submenu {
    width: 100%;
    margin: 4px 20px;
    padding: 0 40px 0 20px;
    display: flex;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    color: rgba(255, 255, 255, 0.56);
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.submenu_main {
    padding: 10px 14px;
    width: 86%;
    font-size: 12px;
    border-radius: 12px;
    border: 0.5px solid #000;
}

.submenu_main_active {
    padding: 10px 14px;
    width: 86%;
    font-size: 12px;
    color: #fff;
    border-radius: 12px;
    border: 0.5px solid #000;
    background: rgba(255, 255, 255, 0.30);
}

.union {
    position: absolute;
    top: -26px;
    left: 30px;
    z-index: -1;
}

.union img {
    width: 12px;
    height: auto;
}

.menu:hover,
.activemenu {
    background: #ffffff33;
    color: #FFF;
}

.subheader:hover,
.activesubhead {
    color: white;
    background: rgba(255, 255, 255, 0.30);
}

.menu svg {
    stroke: white;
    stroke-opacity: 0.56;
}

.menu:hover svg, .activemenu svg {
    stroke: white;
    stroke-opacity: 1;
}

.add {
    display: flex;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 50px;
    gap: 20px;
    border-radius: 28px;
    border: 0.5px solid rgba(255, 255, 255, 0.60);
    background: rgba(56, 14, 36, 0.10);
    color: var(--white-01, #FFF);
    font-size: 16px;
    font-weight: 600;
    line-height: 156%;
    /* 24.96px */
    letter-spacing: 0.16px;
}

.small {
    color: rgba(255, 255, 255, 0.56);
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 160%;
    /* 20.8px */
}

.custom {
    border-radius: 12px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), rgba(0, 0, 0, 0.70);
    color: #000 !important;
    font-size: 14px;
    padding: 6px 10px !important;
    font-weight: 600 !important;
    line-height: 130%;
    /* 18.2px */
    font-family: Inter;
    gap: 1px;
    text-transform: capitalize !important;
}