.search {
    border-radius: 7px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    width: 222px;
    height: 38px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    padding-left: 11px;
}

.search input {
    color: #878790;
    font-family: Work Sans;
    font-size: 14px;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.2px;
    border: none;
    outline: none;
    padding: 0 5px;
}

.list_wrapper {
    color: #fff;
    list-style-type: none;
    padding: 5px 0;
}

.list_wrapper li {
    border-bottom: 1px solid #363636;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
}

.selectinput svg {
    width: 30px;
    height: 17px;
    margin-right: 13px;
}

.survey {
    margin-top: 27px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.error {
    color: red;
    font-size: 13px;
    padding-top: 8px;
    margin-top: -1px;
    z-index: 3;
    position: relative;
    border-top: 1px rgba(232, 14, 14, 0.7) solid;
}

.table {
    width: 100%;
    padding: 33px 4px;
    min-height: 60vh;
}

.head {
    width: 100%;
    color: #878790;
    font-family: Work Sans;
    font-size: 13px;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    padding: 5px 25px 23px 25px;
}

.list {
    width: 100%;
    color: var(--Gray, #3A3A49);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    padding: 18px 25px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #E9EFF4;
}

.list p span {
    color: var(--green-800, #0F5B1D);
    font-family: Work Sans;
    font-size: 12px;
    padding: 5px 10px 7px 10px;
    border-radius: 12px;
    background: var(--green-100, #E6FBD9);
}

.list p .inactive {
    background-color: var(--red-100, #FFE8D7);
    color: #FF0000;
}

.list p svg {
    cursor: pointer;
}

.list p svg:hover {
    opacity: 0.7;
}

.page {
    display: flex;
    align-items: center;
    width: 150px;
    color: var(--Gray, #3A3A49);
    font-family: Work Sans;
    gap: 20px;
    font-size: 13px;
    letter-spacing: -0.26px;
    margin: 10px 50% 10px 35%;
}

.page div {
    color: white;
    background-color: #242220;
    padding: 5px 11px;
    align-items: center;
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    border-radius: 150px;
}

.title {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}

.inputbox {
    color: #8D8D8D;
    font-family: Poppins;
    font-size: 14px;
    border: none;
    width: 100%;
    outline: none;
    padding: 10px 0;
    border-bottom: 1px solid #DFDFDF;
}

form span {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.longtext {
    width: 100%;
}

.option {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.formbtn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 60px;
}

.border {
    width: 100%;
    height: 159px;
    border-radius: 16px;
    border: 2px dashed rgba(141, 141, 141, 0.40);
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.dltImg {
    position: absolute;
    top: 10px;
    right: 15px;
    opacity: 0.8;
    cursor: pointer;
}

.info {
    color: #8D8D8D;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
}

.insights {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    color: #000;
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    height: fit-content;
    background-color: white;
    width: 100%;
}

.posts {
    width: 100%;
    overflow: hidden;
    height: 250px;
    object-fit: cover;
    border-radius: 30px 30px 0px 0px;
}

.person {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 220px;
    left: 60px;
    border: 5px solid white;
}

.clubname {
    color: #000;
    margin-top: 60px;
    font-family: Work Sans;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    /* 109.091% */
}

.subhead {
    display: flex;
    padding: 7px 12px;
    width: fit-content;
    border-radius: 48px;
    background: rgba(24, 24, 24, 0.21);
    color: #000;
    font-family: Work Sans;
    font-size: 12px;
    font-weight: 400;
    margin: 9px 0;
}

.para {
    color: #000;
    font-family: Work Sans;
    font-size: 14px;
}

.postinfo {
    margin: 26px 0 42px 0;
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    color: #333;
    font-family: Work Sans;
    font-size: 14px;
    letter-spacing: -0.2px;
}

.flw {
    border-radius: 22px;
    border: 2px solid rgba(0, 0, 0, 0.7);
    background: 0px -2.75px / 100% 125% no-repeat;
    width: 22px;
    height: 22px;
}

.timeline {
    color: #000;
    text-align: center;
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 500;
    /* 100% */
    padding: 10px;
}

.line {
    width: 100%;
    border: 3px black solid;
    border-radius: 5px;
}

.commentbox {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.30);
    width: 100%;
    padding: 12px 17px 10px 12px;
    margin-top: 12px;
    margin-bottom: 35px;
}

.comment {
    border: none;
    color: rgba(0, 0, 0, 0.50);
    font-size: 15px;
    resize: none;
    outline: none;
    width: 100%;
    height: 60px;
}

.attech {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    gap: 17px;
    /* padding-bottom: 10px; */
}

.commentbox span {
    position: absolute;
    color: #2BA3FA;
    font-family: Work Sans;
    font-size: 16px;
    right: 0;
    top: 0;
    gap: 9px;
    line-height: 140%;
    /* 22.4px */
}

.vendor {
    align-items: center;
    justify-content: space-between;
    display: flex;
    color: #333;
    font-family: Work Sans;
    font-size: 13px;
    line-height: 16px;
    /* 123.077% */
    padding: 6px 0;
}

.vendorpic {
    width: 34px;
    height: 34px;
    border-radius: 34px;
    margin-right: 8px;
}

.vname {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
}

.whenposted {
    gap: 13px;
    font-size: 14px;
    align-items: center;
    display: flex;
}

.uploadimg {
    width: 100%;
    height: 352.572px;
    border-radius: 8px;
    margin-top: 10px;
}

.thought {
    color: rgba(0, 0, 0, 0.70);
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    /* 142.857% */
    padding-top: 17px;
    width: 450px;
}

.more {
    color: #5669FF;
    font-family: Inter;
    font-size: 14px;
    line-height: 20px;
    /* 142.857% */
}

.navigate {
    font-family: Work Sans;
    font-size: 15px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 31px;
}

.smallhead {
    width: 100%;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    /* 111.111% */
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}

.closed {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.40);
}

.opened {
    color: #000;
    font-size: 20px;
}

.vendor_container {
    padding: 3px 0 60px 0;
    min-height: 600px;
}

.user {
    padding: 17px 0;
    gap: 11px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(49, 54, 57, 0.40);
}

.dp {
    width: 36px;
    border-radius: 18px;
    height: 36px;
    object-fit: cover;
}

.username {
    color: #000;
    font-family: Work Sans;
    font-size: 15px;
    line-height: 16px;
    /* 106.667% */
    letter-spacing: 0.15px;
}

.abt {
    color: rgba(97, 97, 97, 0.60);
    font-family: Work Sans;
    font-size: 12px;
    line-height: 14px;
    /* 116.667% */
    letter-spacing: 0.12px;
}