@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}


html {
    --toastify-color-success: #242220 !important;
}

html {
    --toastify-color-progress-light: #242220 !important;
}

body {
    background: #f7f7f7;
}

button {
    cursor: pointer;
}

.container {
    padding: 26px 23px;
    display: flex;
    min-height: calc(100vh - 60px);
    background: rgba(233, 229, 229, 0.3);
    gap: 23px;
    width: calc(100vw - 250px);
}

.insights {
    padding: 26px 23px;
    height: calc(100vh - 60px);
    width: calc(100vw - 250px);
}

.insightcontainer {
    display: flex;
    gap: 23px;
}

.list {
    padding: 22px;
    color: #000;
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    height: fit-content;
    background-color: white;
}

.gap {
    margin-top: 15px;
    position: relative;
}

@keyframes ldio-8u7kjjo29ur {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.ldio-8u7kjjo29ur div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: ldio-8u7kjjo29ur linear 1s infinite;
    background: #fff;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
}

.ldio-8u7kjjo29ur div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #fff;
}

.ldio-8u7kjjo29ur div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #fff;
}

.loadingio-spinner-spinner-ce0myzlaoj5 {
    width: 56px;
    height: 56px;
    display: inline-block;
    overflow: hidden;
    background: rgba(NaN, NaN, NaN, 0);
}

.ldio-8u7kjjo29ur {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.56);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-8u7kjjo29ur div {
    box-sizing: content-box;
}

.slider {}

.slider div {
    outline: none;
    border: none;
}

.slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 15px;
    justify-content: center;
}

.slick-dots.slick-thumb li {
    width: 6px;
    height: 6px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: rgba(255, 255, 255, 0.60);
}

.slick-dots.slick-thumb li.slick-active {
    background-color: #fff;
    width: 24px;
}