.update{
    color: #000;
font-family: Work Sans;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.4px;
}
.form{
    margin: 40px 15px;
    width: 350px;
}
.form label{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 20px; /* 166.667% */
}
.error{
    color: red;
    font-size: 13px;
    padding-top: 8px;
    margin-top: -1px;
    z-index: 3;
    position: relative;
    border-top: 1px rgba(232, 14, 14, 0.7) solid;
}
.form input {
    color: #8D8D8D;
font-family: Poppins;
font-size: 14px;
border: none;
width: 100%;
outline: none;
padding: 10px 0;
border-bottom: 1px solid #DFDFDF;
}
.formbtn{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 60px;
}
.msg{
    color: #333;
font-family: Poppins;
font-size: 14px;
font-weight: 500;
margin: 27px 0px 25px 0px;
}
.formdlt{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
}